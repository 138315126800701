import React, { useState } from "react";
import "./App.css"; // Optional for styling

function App() {
  const [url, setUrl] = useState("");
  const [jsonBody, setJsonBody] = useState("");
  const [method, setMethod] = useState("GET");
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setResponse(null);
    try {
      const options = {
        method,
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (method !== "GET" && jsonBody) {
        options.body = jsonBody;
      }

      const res = await fetch(url, options);
      const result = await res.json();

      if (!res.ok) {
        throw new Error(JSON.stringify(result));
      }

      setResponse(result);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="App">
      <h1>Simple API Client</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>API URL:</label>
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter API URL"
            required
          />
        </div>
        <div>
          <label>JSON Body:</label>
          <textarea
            value={jsonBody}
            onChange={(e) => setJsonBody(e.target.value)}
            placeholder='Enter JSON body {"key": "value"}'
            disabled={method === "GET"} // Disable input for GET requests
          />
        </div>
        <div>
          <label>Method:</label>
          <select value={method} onChange={(e) => setMethod(e.target.value)}>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
          </select>
        </div>
        <button type="submit">Submit</button>
      </form>

      <div>
        <h2>Response:</h2>
        {response && <pre>{JSON.stringify(response, null, 2)}</pre>}
        {error && <pre style={{ color: "red" }}>{error}</pre>}
      </div>
    </div>
  );
}

export default App;
